@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: "Quicksand", sans-serif;
}

body {
    background-image: url('./background.jpg');
    background-size: cover;
    background-position: center;
}

ul {
    list-style: none;
}